import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'
import Slider from 'react-slick'
import Button from './button'
import ProjectHighlight from './projectHighlight'

import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

export default () => (
  <StaticQuery
    query={graphql`
      query {
        projects: allMarkdownRemark(
          filter: { fields: { slug: { regex: "/projects/.*/" } } }
        ) {
          edges {
            node {
              frontmatter {
                title
                longTitle
                graphqlKey
                url
                urlShort
                url2
              }
              fields {
                slug
              }
            }
          }
        }
        projectImages: allFile(
          sort: { order: ASC, fields: [absolutePath] }
          filter: { relativePath: { regex: "/projects/.*1.jpg/" } }
        ) {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const sliderSettings = {
        dots: true,
        infinite: true,
        lazyLoad: true,
        centerMode: true,
        centerPadding: '100px',
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              centerMode: false,
            },
          },
        ],
      }
      return (
        <div className="project-highlights">
          <Container>
            <Row>
              <Col className="top-col">
                <h1>Projects</h1>
                {/* <h2>
                  "We Believe In Innovation & Sustainability As Core
                  Competencies For Success"
                </h2> */}
                <Link to="/portfolio">
                  <Button>View All</Button>
                </Link>
              </Col>
            </Row>
          </Container>
          <Container fluid>
            <div className="project-highlights-wrapper">
              <Row>
                <Col>
                  <Slider {...sliderSettings}>
                    {data.projects.edges.map(({ node }, index) => {
                      const image = data.projectImages.edges.find(edge => {
                        return (
                          edge.node.relativePath ===
                          `projects/${node.frontmatter.graphqlKey}/${
                            node.frontmatter.graphqlKey
                          }1.jpg`
                        )
                      })
                      const fluid = image.node.childImageSharp.fluid
                      return (
                        <ProjectHighlight
                          key={`pr-${node.id}`}
                          node={node}
                          fluid={fluid}
                        />
                      )
                    })}
                  </Slider>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      )
    }}
  />
)

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
