import React from 'react'
import { Container, Row, Col } from 'reactstrap'

export default () => (
  <>
    <a class="anchor" id="about" />
    <div className="about-outer-container">
      <Container className="px-2 px-md-5">
        <Row>
          <Col className="top-col page">
            <h1>About Us</h1>
          </Col>
        </Row>
      </Container>
      <Container className="px-2 px-md-5 about">
        <Row>
          <Col>
            {/* TODO: Style */}
            <p className="focus-paragraph">
              We are a privately held real estate development company based in
              San Francisco. We specialize in creative solutions sought by
              technology companies at the forefront of innovation. We invest in
              prime Bay Area locations that benefit from proximity to mass
              transit and major employment centers.
            </p>
            <ul>
              <li>
                We partner with investors, tenants and local communities to
                ensure participation and sustainability, creating destinations
                that inspire creativity and motivation for our tenants while
                adding value to our investor and local communities.
              </li>
              <li>
                We specialize in prime one-off properties that meet our
                standards for parcel assembly, location, amenities and
                development potential.
              </li>
              <li>
                Guided by integrity, quality, innovation, and creativity, our
                goal is to be the solution of choice for our investors, tenants,
                and communities we serve.
              </li>
            </ul>
          </Col>
          {/* <Col className="image-col">
            <img src={require('../assets/images/about.jpg')} />
          </Col> */}
        </Row>
      </Container>
    </div>
  </>
)
