import React from 'react'
import { Link } from 'gatsby'
// import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import Button from './button'

export default props => {
  const { node, fluid } = props
  const slug = node.fields.slug
  const title =
    node.frontmatter.longTitle !== null
      ? node.frontmatter.longTitle
      : node.frontmatter.title

  console.log('title: ', title)
  return (
    <Link to={slug}>
      {/* <BackgroundImage
        Tag="section"
        className="project-highlight"
        fluid={fluid}
      >
        <span className="title">{node.frontmatter.title}</span>
        <Button styleClass="explore-button">Explore</Button>
      </BackgroundImage> */}
      <Img alt="" fluid={fluid} />
      <span className="title">{title}</span>
      <Button styleClass="explore-button">Explore</Button>
    </Link>
  )
}
