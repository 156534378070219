import React from 'react'
// import { graphql } from 'gatsby'

import Layout from '../components/layout'
import HeroSection from '../components/heroSection'
import About from '../components/about'
import ProjectHighlightsSection from '../components/projectHighlightsSection'
import PressHighlightsSection from '../components/pressHighlightsSection'
import Contact from '../components/contact'

export default () => (
  <Layout>
    <HeroSection />
    <About />
    <ProjectHighlightsSection />
    <PressHighlightsSection />
    <Contact />
  </Layout>
)
