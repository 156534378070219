import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import FormContact from './formContact'

export default () => (
  <>
    <a class="anchor" id="contact" />

    <div className="contact-us">
      <Container>
        <Row>
          <Col className="top-col pb-0 d-flex justify-content-center">
            <h1>Contact Calvano Development</h1>
            <p>
              <em>All fields are required</em>
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col md={5}>
            <main>
              <article>
                {/* <h3>Millbrae Station</h3> */}
                <FormContact />
              </article>
            </main>
          </Col>
          <Col md={{ size: 5, offset: 1 }} className="image-col">
            {/* TODO: Use gatsby-image */}
            <img src={require('../assets/images/contact.jpg')} />
            {/* <div className="contact-grid">
            <img src={require('../assets/images/map.png')} />
            <div className="contact-address">
              <span>
                900 Bush Street, PH #1
                <br />
                San Francisco, CA 94109
              </span>
            </div>
            <img src={require('../assets/images/phone.png')} />
            <div className="contact-phone">
              <span>(415) 922– 0449</span>
            </div>
          </div> */}
          </Col>
        </Row>
      </Container>
    </div>
  </>
)
