import React from 'react'
import { Container, Row, Col } from 'reactstrap'
// import QuoteCarousel from './quoteCarousel'
import RecentPress from './recentPress'

export default () => (
  <div className="press-highlights">
    <Container>
      <Row>
        <Col className="top-col">
          <h1>Recent Press</h1>
        </Col>
      </Row>
    </Container>
    <Container fluid>
      <Row>
        <Col>
          <RecentPress />
        </Col>
      </Row>
    </Container>
  </div>
)
